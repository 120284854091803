import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

let lastTime = Date.now();

// Set up canvas and scene
const canvas = document.querySelector("canvas.webgl");
const scene = new THREE.Scene();

// Set up camera
const camera = new THREE.PerspectiveCamera(
  20,
  window.innerWidth / window.innerHeight,
  0.1,
  10000
);
camera.position.set(0, 0.01, 0.4);
camera.rotation.set(0, 0, 0);

// Set up renderer
const renderer = new THREE.WebGLRenderer({ antialias: true, canvas: canvas });
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 10;
renderer.setAnimationLoop(render);

//load model and add to scene
const loader = new GLTFLoader();
const modelUrl = new URL("/src/models/element.gltf", import.meta.url);
let model;
loader.load(modelUrl.toString(), function (gltf) {
  model = gltf.scene;
  
  // Set the scale of the model
  const scaleFactor = 5; // Adjust this value as per your requirements
  model.scale.set(scaleFactor, scaleFactor, scaleFactor);

  scene.add(model);
});

// Add hemisphere light to scene
const hemiLight = new THREE.HemisphereLight(0xb2389b, 0x35db6f, 1);
scene.add(hemiLight);

// Render loop
function render() {
  let deltaTime = Date.now() - lastTime;
  lastTime = Date.now();

  if (model) {
    model.rotation.y += deltaTime * 0.0001;
    //model.rotation.x = Math.sin(Date.now() * 0.0001)/10;
  }

  const hue = (Date.now() * 0.00002) % 1;
  const skyColor = new THREE.Color().setHSL(hue, 1, 0.5);
  const groundColor = new THREE.Color().setHSL((hue + 0.5) % 1, 1, 0.5);

  hemiLight.color = skyColor;
  hemiLight.groundColor = groundColor;
  renderer.render(scene, camera);
}

// Set up resize event listener
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

window.addEventListener("resize", onWindowResize);


render(); //the update loop